import './App.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';
import Building from './Maintainance/Building';

function App() {
  return (
    <div>
    <Building />
    </div>
  );
}



export default App;
