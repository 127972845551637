import { Image } from 'primereact/image';
import under_construction from '../assets/under_construction.gif'
function Building() {


    return (
        <div style={containerStyle}>
            <Image src={under_construction}
           
            alt="Image" width="100%" />
        </div>
    )

}

const containerStyle = {
    backgroundColor:"#4b52db",
    height:"100vh",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
}

export default Building